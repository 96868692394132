import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter,RouterProvider,createRoutesFromElements,Route,Navigate } from "react-router-dom";

import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate.js";


import { AuthenticationGuard } from "./components/authentication-guard.js";
import { CallbackPage } from "./pages/CallbackPage.jsx";
import { HomePage } from "./pages/HomePage.jsx";
import { NotFoundPage } from "./pages/NotFound.jsx";
import { Dashboard } from "./pages/Dashboard.jsx";
import { Floors } from "./pages/Floors.jsx";
import { sensorDataLoader } from "./pages/Dashboard.jsx";
import { floorDataLoader } from "./pages/Floors.jsx";

import { ChakraProvider } from "@chakra-ui/react";
import { IndoorAirPage } from "./pages/InDoorAirPage.jsx";
import { DisabledToiletsPage } from "./pages/DisabledToiletsPage.jsx";
import { RootTopExitsPage } from "./pages/RootTopExitsPage.jsx";
import { WaterLeaksPage } from "./pages/WaterLeaksPage.jsx";


const container = document.getElementById("root");
const root = createRoot(container);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Auth0ProviderWithNavigate/>}>
      <Route path="/" element={<AuthenticationGuard component={HomePage}/> }>
        <Route index  loader={sensorDataLoader} element={<AuthenticationGuard component={Dashboard} />}
        />
        <Route path="/dashboard" loader= {sensorDataLoader} element={<AuthenticationGuard component={Dashboard}
          errorElement= {<NotFoundPage />}/>}></Route>
            <Route path="/dashboard/indoorair"  element={<AuthenticationGuard component={IndoorAirPage}
              errorElement= {<NotFoundPage />}/>}></Route>
            <Route path="/dashboard/disabledtoilets"  element={<AuthenticationGuard component={DisabledToiletsPage}
              errorElement= {<NotFoundPage />}/>} ></Route>  
            <Route path="/dashboard/rooftopexits"  element={<AuthenticationGuard component={RootTopExitsPage}
              errorElement= {<NotFoundPage />}/>} ></Route>  
            <Route path="/dashboard/waterleakages"  element={<AuthenticationGuard component={WaterLeaksPage}
              errorElement= {<NotFoundPage />}/>} 
        />

        <Route path="/floors" loader = {floorDataLoader} element={<AuthenticationGuard component={Floors}
          errorElement= {<NotFoundPage />}/>}
        />
      </Route>
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />      
    </Route>
  )
);

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>
);
