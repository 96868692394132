import { Box,SimpleGrid,Stack,Text,Select, HStack } from '@chakra-ui/react';
import { FallDetectDevice } from '../components/FallDetectDevice.jsx';
import { useLoaderData,redirect, useLocation } from 'react-router-dom';

function getCurrentFloorDevices(devices,floor,type){
    return devices.filter((device)=> (device.floor === floor ));
};

export const DisabledToiletsPage = () => {
    const    location = useLocation();
    const  disabledToilets = location.state;
    return(
        <Box mt="20px" ml='20px' mr='20px'>
        <Text mb='20px' ml='5px' fontSize='sm'></Text>
        <SimpleGrid columns={[1,1,2,2,3,3,4]} spacing='10px'>
        {disabledToilets.map((e)=> 
            <FallDetectDevice key={e.deviceId} device = {e }  />)}
        </SimpleGrid>
    </Box>

    )
}

