import { Box,SimpleGrid,Stack,Text,Select, HStack } from '@chakra-ui/react';
import { MotionDetectDevice } from '../components/MotionDetectDevice.jsx';
import { useLoaderData,redirect, useLocation } from 'react-router-dom';


export const RootTopExitsPage = () => {
    const    location = useLocation();
    const  motionDetections = location.state;
    return(
        <Box mt="20px" ml='20px' mr='20px'>
        <Text mb='20px' ml='5px' fontSize='sm'></Text>
        <SimpleGrid columns={[1,1,2,2,3,3,4]} spacing='10px'>
        {motionDetections.map((e)=> 
            <MotionDetectDevice key={e.deviceId} device = {e }  />)}
        </SimpleGrid>
    </Box>

    )
}

