import { FiMenu } from "react-icons/fi";
import { IconButton } from "@chakra-ui/react";
import { useSidenav } from "./SidenavContext";
import { Flex, Text, Spacer } from '@chakra-ui/react'
import React from 'react'
import LogoutButton from '../../buttons/LogoutButton'

export function NavBar2() {
  const { onOpen } = useSidenav();
  return (
    <Flex as="nav" p="10px" alignItems="center" bg="orange.100">
        <IconButton
            bg="orange.100"
            aria-label="menu"
            display={{ base: "flex", md: "none" }}
            onClick={onOpen}
            icon={<FiMenu />}
        />

        <Text as='b' fontSize="2xl">保良局李兆基青年綠洲</Text>
  <Spacer/>
  <LogoutButton>Logout</LogoutButton>
</Flex>
  );
}