import { Box,SimpleGrid,Stack,Text,Select, HStack } from '@chakra-ui/react';
import { Device } from '../components/Device.jsx';
import { DryerStatusBox } from '../components/DryerStatusBox.jsx';
import { useLoaderData } from 'react-router-dom';
import {useState,useEffect} from 'react';

function getCurrentFloorDevices(devices,floor,type){
    return devices.filter((device)=> (device.floor === floor ));
};
//&& device.deviceType === type
export const Floors = () => {
   
        const deviceList = useLoaderData();
        
        const [floors, setFloors] = useState([]);
        const [selectedFloor, setSelectedFloor] = useState('1');

        useEffect(() => {
          fetch(domain+'/buildinginfo/getAllFloors')
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              console.log(data);
              setFloors(data);
            });
        }, []);
        const  handleChange = (e)=>{
         setSelectedFloor(e.target.value);
        }
        const currentFloorDevices = getCurrentFloorDevices(deviceList,selectedFloor);
    return (
        <>
            <HStack pr='10px' pt='5px' pb='5px' justify='right'>
            <Box direction ='flex' >
                <Select size='sm' placeholder='Select floor' onChange={handleChange}>
                    {floors.map((floor) =><option key={floor.buildingInfoId} value={floor.floor}>{floor.description}</option>)}
                </Select>                
            </Box>
            </HStack>
            <Box mt="20px" ml='20px' mr='20px'>
                <Text mb='20px' ml='5px' fontSize='sm'></Text>
                <SimpleGrid columns={[1,2,3,4,4]} spacing='20px'>
                {currentFloorDevices.map((e)=> 
                    <Device key={e.deviceId} device = {e }  />)}
                </SimpleGrid>
            </Box>
        </>      
    
    )
}

//</Box>        
//                <Box mt="20px" ml='20px' mr='20px'>
//                <Text mb='20px' ml='5px' fontSize='sm'>Dryer Machines</Text>
//                <SimpleGrid columns={[1,2,3,4,4]} spacing='20px'>
//                {currentFloorDevices.map((e)=> 
//                    <DryerStatusBox device = {e }  />)};
//                    
 //               </SimpleGrid>
 //           </Box>  
const domain = process.env.REACT_APP_API_SERVER_URL;
const getDeviceOnAllFloors = domain +"/Devices/getDevicesOnAllFloors";
export const  floorDataLoader = async () => {
    const res = await fetch(getDeviceOnAllFloors);
    const result = await res.json();
    return result
}


