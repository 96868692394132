import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { HomePage } from "./HomePage.jsx";
import { Text, Heading } from '@chakra-ui/react'

export const CallbackPage = () => {
  const { error } = useAuth0();

  if (error) {
    return (
      <>
      <Heading>Error</Heading>
      <Text>{error.message}</Text>
      </>
    );
  }

  return (
    <HomePage/>
  );
};
