import React from 'react'
import { Box,Text} from '@chakra-ui/react'
import wmIcon from '../images/sensor2.png'

export const  MotionDetectDevice=({device}) => {
  return (
    <Box mb='40px'>
      <Text fontSize='sm' m='2px'>{device.floor} Floor {device.location} </Text>
      <Box minW='300px' borderWidth='1px' borderRadius='lg' overflow='hidden'>
        <Box m='5px' display='flex' alignItems='center'>
            <img width={14} height={14}  src={wmIcon}/>
            <Text ml='4px' fontSize='lg'>Motion Detection</Text>
        </Box>
        <Box  mt='10px' mb='30px' align='center'>
        <Text fontSize='xl' m='10px'>{device.status === '1' ? 'Motion Detected':'Normal'} </Text>  
        </Box>      
      </Box>
    </Box>
  )
}