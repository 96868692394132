import { Box,SimpleGrid,Stack,Text,Select, HStack } from '@chakra-ui/react';
import { AirQualityDevice } from '../components/AirQualityDevice.jsx';
import { DryerStatusBox } from '../components/DryerStatusBox.jsx';
import { useLoaderData,redirect, useLocation } from 'react-router-dom';
import {useState,useEffect} from 'react';

function getCurrentFloorDevices(devices,floor,type){
    return devices.filter((device)=> (device.floor === floor ));
};
//&& device.deviceType === type
export const IndoorAirPage = () => {
    const    location = useLocation();
    const  airQuality = location.state;
    return(
        <Box mt="20px" ml='20px' mr='20px'>
        <Text mb='20px' ml='5px' fontSize='sm'></Text>
        <SimpleGrid columns={[1,1,2,2,3,3,4]} spacing='10px'>
        {airQuality.map((e)=> 
            <AirQualityDevice key={e.deviceId} device = {e }  />)}
        </SimpleGrid>
    </Box>

    )
}

//"http://localhost:6060/Devices/getDevicesWithAlertOn"
const domain = process.env.REACT_APP_API_SERVER_URL;
const getSensorDataApi = domain +"/Devices/getDevicesWithAlertOn";
export const  indoorSensorDataLoader = ()=> redirect("/indoorsensor");
//async () => {
//    const res = await fetch(getSensorDataApi);
//    const result = await res.json();
//    return result
//}
